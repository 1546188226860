<template>
  <div>
    <div v-if="viewno == 1">
      <div class="col-md-12 font">
        <div class="cardbox">
          <div class="col-md-12">
            <div class="row" style="margin-top: 20px">
              <div class="col-md-6">
                <h3 class="page-header">
                  <i class="fa fa fa-users animated bounceInDown show-info"></i>
                 Sub Category List
                </h3>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-md-6"></div>
                  <div class="col-md-6" style="padding-top: 18px">
                    <button @click="refresh()" class="btn btn-primary">
                      <i class="fa fa-refresh"></i> Refresh
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="memberdivtable_1">
            <div
              class="card-body"
              style="border: 1px solid #e9dbdb; padding: 0"
            >
              <div class="form-group mb-2">
                <div class="co-md-12">
                  <div class="row" style="padding-left: 40px; margin-top: 8px">
                    <div class="col-md-4 row">
                      <p><b>Search Category Name</b></p>
                      <input
                        type="Search"
                        v-model="searchname"
                        class="form-cascade-control input-box-short"
                      />
                    </div>
                    <div class="col-md-3">
                      <button
                        type="button"
                        @click="searchName()"
                        style="margin-top: 38px"
                        class="btn btn-success btn-sm btnsearch"
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <nav
          aria-label="Page navigation example"
          style="float: right; margin-right: 28px"
        >
          <paginate
            style="margin: 0px !important"
            :page-count="noofpages"
            :click-handler="clickCallback"
            :prev-text="'&laquo; Prev &nbsp;&nbsp;'"
            :next-text="'Next &raquo;'"
            :container-class="'pagination'"
          >
          </paginate>
        </nav>

        <div class="cardbox1">
          <div class="col-md-12">
            <table class="table table-font">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Category</th>
                  <th>Sub Category</th>
                  <th style="width: 10%">Image</th>
                  <th style="width: 8%">Action</th>
                </tr>
              </thead>
              <tr v-for="(item, index) in filters" v-bind:key="item.id" :item="item" :index="index" >
                    <td>{{index+1}}</td>
                    <td><div>{{item.parent_id}}</div></td>
                    <td>{{item.name}}</td>
                    <td></td>
                    <td></td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Constants from "../../components/utilities/Constants.vue";
export default {
  mixins: [Constants],
  components: {
  },
  data() {
    return {
      categoryArray: [],
      status: { 0: "Disabled", 1: "Active" },
      isedit: false,
      category: null,
      size: 250,
      attributes: [],
      attribcategorymap: [],

      searchname: "",
      searchurl: "",
      searchrole: 0,
      searchstatus: 1,
      noofpages: 0,
    };
  },
  mounted() {
    this.$store.dispatch("fetchcategories");
    this.$store.dispatch("fetchattributes");
  },
  computed: {
    filters(){
      return this.categories.filter(item => item.parent_id > 0 )
    },
    ...mapGetters(["loggedinuser", "viewno", "categories"]),
  },
  methods: {
    processloggedinuser(data) {
      this.$store.commit("assignloggedinuser", data);
    },

    processCategoryResponse(data) {
      this.$store.commit("assigncategories", data);

      this.page = 1;
    },

  },
};
</script>
<style scoped>
.table td,
.table th {
  border: 1px solid #dee2e6;
}
</style>